<template>
<v-container grid-list-xl fluid>
    <v-layout row wrap>
        <v-flex lg12>
            <v-card>
                <hpo-filter :headers="headers" :viewName="viewName" v-on:applyFilter="applyFilter" v-on:quickFilter="quickFilter"></hpo-filter>
                <v-data-table fixed-header height="750px"   :headers="headers" :items="items" :options.sync="pagination" :server-items-length="total_rows" :footer-props="items_per_page" :must-sort="true" :loading="loading" :mobile-breakpoint="100">
                    <template v-slot:header="{ props }"><table-header-filter :headers="props.headers" :viewName="viewName" v-on:applyFilter="applyFilter"></table-header-filter></template>
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>{{ item.ID }}</td>
                            <td>{{ item.Username }}</td>
                            <td>{{ item.Email }}</td>
                            <td>{{ item.FullName }}</td>
                            <td>{{ roleTypeDisplay(item.RoleType) }}</td>
                            <td v-bind:style="{ 'color': item.is_active == 1 ? '#0059b3' : '#e63900'}">{{ item.is_active == 1 ? 'Đã kích hoạt' : 'Đang bị khóa' }}</td>
                            <td class="text-center" v-if="item.ID > 9999990">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon medium class="mr-2" color="red" @click="resetPassword(item.ID)" v-on="on">fa-redo</v-icon>
                                    </template>
                                    <span>Reset mật khẩu về mặc định</span>
                                </v-tooltip>                                
                                <v-icon medium class="mr-2" @click="gotoDetail(item.ID)">fa-edit</v-icon>
                                <v-icon medium @click="deleteItem(item.ID)">fa-trash-alt</v-icon>
                            </td>
                            <td class="text-center" v-else>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card>
        </v-flex>
    </v-layout>
</v-container>
</template>

<script>
import { mapState } from "vuex";
import configs from "../../configs";
import Filtering from "@/components/Filtering";
import _ from "lodash";
import md5 from "md5";

export default {
    components: {
        "hpo-filter": Filtering
    },
    data() {
        let dataType = configs.DATA_TYPE;
        let roles = this.$store.state.roles.all.data.map(obj =>{ 
            var rObj = {};
            rObj['value'] = obj.ID;
            rObj['name'] = obj.RoleName;
            return rObj;
        });
        return {
            headers: [{
                    text: "ID",
                    value: "ID",
                    filterable: false,
                    dataType: dataType["Number"]
                },
                {
                    text: "User Name",
                    value: "Username",
                    filterable: true,
                    dataType: dataType["String"]
                },
                {
                    text: "Email",
                    value: "Email",
                    filterable: true,
                    dataType: dataType["String"]
                },
                {
                    text: "Họ tên",
                    value: "FullName",
                    filterable: true,
                    dataType: dataType["String"]
                },
                {
                    text: "Quyền hạn",
                    value: "RoleType",
                    filterable: false,
                    dataType: dataType["Enum"],
                    values: roles
                },
                {
                    text: "Trạng thái",
                    value: "is_active",
                    filterable: true,
                    dataType: dataType["Number"]
                },
                {
                    text: "Thao tác",
                    value: "",
                    align: "center",
                    sortable: false,
                    filterable: false
                }
            ],
            pagination: {  ...configs.PAGINATION,  sortBy: ["ID"]  },
            items_per_page: _.cloneDeep(configs.ITEMS_PER_PAGE),
            role_list: roles,
            viewName: "staff_list",
            filterConditions: [],
            quickFilterConditions: [],
            requireRules: [v => !!v || "Trường dữ liệu không được để trống."],
        };
    },
    watch: {
        pagination: {
            handler: function (val, oldVal) {
                let filterConditions = this.$store.state.commons.filterConditions;
                this.filterConditions = !_.isEmpty(filterConditions) ? filterConditions[this.viewName] : []
                this.filter_data();
            },
            deep: true
        },
    },
    computed: mapState({
        items: state => state.staff.all.data,
        total_rows: state => state.staff.all.total,
        loading: state => state.staff.loading,
        currentStaff: state => state.staff.selected
    }),
    methods: {
        filter_data: function () {
            var param = {
                pagination: this.pagination,
                filter: this.filterConditions,
                quickFilter: this.quickFilterConditions
            };
            this.$store.dispatch("staff/getList", param);
        },
        gotoDetail: function (id) {
            this.$router.push({
                name: "StaffDetail",
                params: {
                    Pid: id,
                    title: `Chi tiết nhân viên (#${id})`
                }
            });
        },
        deleteItem: function(id) {
            window.getApp.$emit('REQUEST_DELETE_DATA', id);
        },
        resetPassword: function(id) {
            this.$store.dispatch("staff/setDetail", {
                'data': {Password: md5('123456')},
                'id': id,
                'notify': 'Đã reset mật khẩu về mặc định (123456)'
            });
        },
        applyFilter(filterConditions) {
            let sortBy = this.pagination.sortBy
            let sortDesc = this.pagination.sortDesc
            this.pagination = configs.PAGINATION;
            this.pagination.sortBy = sortBy;
            this.pagination.sortDesc = sortDesc;
            this.filterConditions = filterConditions;
            this.filter_data();
        },
        quickFilter(filterConditions) {
            this.quickFilterConditions = filterConditions;
            this.filter_data();
        },
        createData(username = '') {
            this.$router.push({
                name: "StaffDetail",
                params: {
                Pid: 'tao-moi',
                title: `Tạo mới nhân viên`
                }
            });
        },
        storeDisplay(value) {
            const store = _.find(this.stores, {'ID': value});
            return store ? store.Name : '';
        },
        roleTypeDisplay(value) {
            var ret = '';
            if ((value & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin){
                ret = ret + 'Admin; '
            }
            if ((value & configs.ROLE_TYPE.Purchase) == configs.ROLE_TYPE.Purchase) {
                ret = ret + 'CSKH; '
            }
            if ((value & configs.ROLE_TYPE.CustomerService) == configs.ROLE_TYPE.CustomerService) {
                ret = ret + 'Tiếng Trung; '
            }
            if ((value & configs.ROLE_TYPE.StoreVN) == configs.ROLE_TYPE.StoreVN) {
                ret = ret + 'Kho VN; '
            }
            if ((value & configs.ROLE_TYPE.StoreTQ) == configs.ROLE_TYPE.StoreTQ) {
                ret = ret + 'Kho TQ; '
            }
            if ((value & configs.ROLE_TYPE.Payment) == configs.ROLE_TYPE.Payment) {
                ret = ret + 'Kế toán;'
            }
            return ret;
        }
    },
    mounted() {
        this.$root.$emit('REQUEST_ROOT_OPTION', {});
        this.$root.$on("REQUEST_CREATE_DATA", this.createData);
        this.$root.$on("REQUEST_REFRESH_DATA", this.filter_data);
        this.$root.$on("CONFIRMED_REMOVE_DATA", id => {
            this.$store.dispatch("staff/removeDetail", id);
            setTimeout(this.filter_data, 200);
        });
    },
    beforeDestroy() {
        this.$root.$off("REQUEST_CREATE_DATA", this.createData);
        this.$root.$off("REQUEST_REFRESH_DATA", this.filter_data);
        this.$root.$off("CONFIRMED_REMOVE_DATA");
    }
};
</script>
